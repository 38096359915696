/* BEGIN: Set domain-specific values */
// Subdomain override styles
import "@css/webviews-swingu/var.css";
// Path to subdomain layout file
import Layout from "@components/webviews-swingu/layout-default.vue";
// `js/components/${vueDir}/`
let vueDir = "webviews-swingu";
// Accessible in Vue via `this.$constants`
const subdomainConstants = {
    inAppEvents: {
        home_basic_stats_tap: "home_basic_stats_tap",
        home_play_tap: "home_play_tap",
        home_upgrade_tap: "home_upgrade_tap",
        upgrade_tap: "upgrade_tap",
        play_cta_tap: "play_cta_tap"
    }
};
/* END: Set domain-specific values */

/**
 * SETUP NOTE: Subdomain must also be added to vite.config.js
 */

import { init } from "./main";
init(vueDir, Layout, subdomainConstants);
